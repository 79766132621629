import React, { useEffect } from "react"
import Seo from "../components/seo"
import BrandHeading from "../components/brand-heading";
import { FormLinkButton } from '../components/form-elements';
import { useCartActionContext } from "../components/cart-context";
import { useCheckoutActionContext } from "../components/checkout-context";

export default function CheckoutSuccess() {
    const cartStore = useCartActionContext();
    const checkoutStore = useCheckoutActionContext();
    
    useEffect(
        () => {
            cartStore.clearAll();
            checkoutStore.clearAll();            
        }
        , []);

    return (
        <>
            <Seo title="Checkout Success" />
            <BrandHeading title="checkout" />
            <section className="text-center">
                <div className="bg-pas-peach text-pas-white 
                                py-[1.62rem]
                                font-ovo text-center text-lg md:text-xl tracking-widest
                                rounded-sm">
                    <p className="leading-4 px-4">
                        THANK YOU FOR YOUR ORDER!
                    </p>
                </div>
                <div className="text-center text-xs md:text-[.80rem] tracking-wider md:tracking-[.06em] text-pas-gray my-9 md:my-14 mx-1 md:mx-11 lg:mx-56">
                    <p className="leading-6 md:leading-8 my-1">You should receive a confirmation email regarding your cake order shortly.</p>
                    <p className="leading-6 md:leading-8 my-1">We will contact you within 48 hours to finalise your cake order.</p>
                    <p className="leading-6 md:leading-8 my-7 md:my-1">Please check your spam / junk folder if you haven't received any email from us within this timeframe.</p>
                    <p className="leading-6 md:leading-8 my-7 md:my-6">In the meantime, check out the rest of our site for more delightful things we have in store for you!
                    </p>
                </div>
                <div className="flex flex-col max-w-xs mx-auto font-ovo bold text-base md:text-lg text-center tracking-widest">
                    <FormLinkButton label={"OUR PRODUCTS"} to={"/cakes"} className={"mb-4"} />
                    <FormLinkButton label={"WHO WE ARE"} to={"/about"} className={"mb-4"} />
                    <FormLinkButton label={"HOME"} to={"/"} className={"mb-4"} />
                </div>
            </section>
        </>
    )
}